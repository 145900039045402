import * as React from 'react';

import Layout from '../../components/layout/layout';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './contact-us.module.scss';

export default function ContactUsPage() {
  return (
    <Layout title="Contact Us">
      <StaticImage
        src="../../images/contact-page-banner.jpg"
        layout="fullWidth"
        placeholder="blurred"
        alt=""
        className={styles.bannerImage}
      />
    </Layout>
  );
}
